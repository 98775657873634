import React, { useEffect } from 'react';
import { Container, Alert, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { trackEvent } from './trackGA';

const SuccessPage = () => {
  useEffect(() => {
    // Check if gtag is available
    if (window.gtag) {
      console.log('gtag is available, sending event');
      window.gtag('event', 'manual_event_PURCHASE', {
        // <event_parameters>
      });
    }

    // Facebook Pixel conversion tracking
    if (window.fbq) {
      console.log('Facebook Pixel is available, sending Purchase event');
      window.fbq('track', 'Purchase', {
        currency: 'USD',
        value: 0.00 // You might want to pass the actual purchase value here
      });
    }

    // Track event using our custom tracking function
    trackEvent('Ecommerce', 'Purchase', 'Success Page');
  }, []);

  return (
    <Container className="py-5 text-center">
      <Alert variant="success">
        <Alert.Heading>Thank you for your purchase!</Alert.Heading>
        <p>Your order has been successfully placed.</p>
      </Alert>

      <p>
        You will receive a confirmation email within a day.
        You'll receive another email when your order ships.
      </p>
      <p>If you have any questions, please send an email to <a href="mailto:support@aipainttee.com">support@aipainttee.com</a>.</p>
      <Button as={Link} to="/" variant="primary" size="lg" className="mt-3">
        Continue Shopping
      </Button>
    </Container>
  );
};

export default SuccessPage;
