import React from 'react';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import { trackEvent } from './trackGA';

const Designer = ({
    styles,
    targets,
    designStyle, setDesignStyle,
    target, setTarget,
    customStyle, setCustomStyle,
    customTarget, setCustomTarget,
    isCustomStyleActive, setIsCustomStyleActive,
    isCustomTargetActive, setIsCustomTargetActive,
    customRequirements, setCustomRequirements,
}) => {

    const handleCustomStyleChange = (e) => {
        setCustomStyle(e.target.value);
        setDesignStyle(e.target.value);
        setIsCustomStyleActive(true);
    };

    const handleCustomTargetChange = (e) => {
        setCustomTarget(e.target.value);
        setTarget(e.target.value);
        setIsCustomTargetActive(true);
    };

    const handleStyleButtonClick = (style) => {
        setDesignStyle(style);
        setIsCustomStyleActive(false);
        trackEvent('Design', 'Style Button Click', style);
    };

    const handleTargetButtonClick = (t) => {
        setTarget(t);
        setIsCustomTargetActive(false);
        trackEvent('Design', 'Target Button Click', t);
    };

    const handleCustomRequirementsChange = (e) => {
        setCustomRequirements(e.target.value);
    };

    const activeInputStyle = {
        backgroundColor: '#007bff',
        color: '#fff',
        borderColor: '#007bff',
    };

    const outlinePrimaryStyle = {
        backgroundColor: 'transparent',
        color: '#007bff',
        borderColor: '#007bff',
    };

    return (
        <Container className="mb-5">
            <Row className="mb-3 border rounded py-2" style={{ backgroundColor: '#f8f9fa' }}>
                <Col>
                    <h3 id="ai-designer">STYLE</h3>
                    {styles.map((style) => (
                        <Button
                            key={style}
                            variant={designStyle === style && !isCustomStyleActive ? 'primary' : 'outline-primary'}
                            className="me-2 mb-2"
                            onClick={() => handleStyleButtonClick(style)}
                            style={designStyle !== style || isCustomStyleActive ? outlinePrimaryStyle : {}}
                        >
                            {style}
                        </Button>
                    ))}
                    <Form.Control
                        type="text"
                        placeholder="Or enter your own style"
                        value={customStyle}
                        onChange={handleCustomStyleChange}
                        className={`mt-2`}
                        onFocus={() => setIsCustomStyleActive(true)}
                        style={isCustomStyleActive ? activeInputStyle : {}}
                    />
                </Col>
            </Row>
            <Row className="mb-3 border rounded py-2" style={{ backgroundColor: '#f8f9fa' }}>
                <Col>
                    <h3>TARGET</h3>
                    {targets.map((t) => (
                        <Button
                            key={t}
                            variant={target === t && !isCustomTargetActive ? 'primary' : 'outline-primary'}
                            className="me-2 mb-2"
                            onClick={() => handleTargetButtonClick(t)}
                            style={target !== t || isCustomTargetActive ? outlinePrimaryStyle : {}}
                        >
                            {t}
                        </Button>
                    ))}
                    <Form.Control
                        type="text"
                        placeholder="Or enter your own recipient or occasion"
                        value={customTarget}
                        onChange={handleCustomTargetChange}
                        className={`mt-2 mb-3`}
                        onFocus={() => setIsCustomTargetActive(true)}
                        style={isCustomTargetActive ? activeInputStyle : {}}
                    />
                </Col>
            </Row>
            <Row className="mb-3 border rounded py-2" style={{ backgroundColor: '#f8f9fa' }}>
                <Col>
                    <h4>OPTIONAL DETAILS</h4>
                    <Form.Text className="text-muted mb-2">
                        Please use full sentences to describe your requirements.<br />
                        <strong>We do not support text in the image yet. If you need text, after the image is generated, a CAPTION input box will appear for you to add text.</strong>
                    </Form.Text>
                    <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Example: include a cat with a hat swimming in the ocean."
                        className="mt-2"
                        value={customRequirements}
                        onChange={handleCustomRequirementsChange}
                        style={customRequirements ? activeInputStyle : {}}
                    />
                </Col>
            </Row>
        </Container>
    );
};

export default Designer;
