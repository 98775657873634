import React, { useState, useEffect } from 'react';
import { Modal, Form, Button, Alert } from 'react-bootstrap';
import getCookie from './GetCookie';

const EmailPopup = ({ show, setShow}) => {
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState('');

  const handleClose = () => setShow(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const response = await fetch('/submit-email/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': getCookie('csrftoken'),
        },
        body: JSON.stringify({ email }),
      });
      const data = await response.json();
      if (response.ok) {
        setMessage(data.message);
        localStorage.setItem('hasSubmittedEmail', 'true');
        setTimeout(() => handleClose(), 3000); // Close popup after 3 seconds
      } else {
        setMessage(data.error);
      }
    } catch (error) {
      setMessage('An error occurred. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>While waiting, get 10% Off Your First Order!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Enter your email to receive a coupon code for 10% off your first purchase.</p>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Control
              type="email"
              placeholder="Enter email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </Form.Group>
          <Button variant="primary" type="submit" disabled={isSubmitting}>
            {isSubmitting ? 'Submitting...' : 'Get My Coupon'}
          </Button>
        </Form>
        {message && <Alert className="mt-3" variant={message.includes('error') ? 'danger' : 'success'}>{message}</Alert>}
      </Modal.Body>
    </Modal>
  );
};

export default EmailPopup;
