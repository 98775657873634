import React from 'react';
import { Modal, Button, Form, Image } from 'react-bootstrap';
import { trackEvent } from './trackGA';
import getCookie from './GetCookie';

const Checkout = ({ cartItems }) => {
    console.log('Checkout component rendered with cart items:', cartItems);
    const handleCheckout = async () => {
        console.log('Starting checkout process');
        trackEvent('Product', 'Checkout', cartItems.length.toString());
        try {
            console.log('Sending request to create checkout session');
            const requestBody = {
                items: cartItems.map(item => ({
                    product_name: item.product.productTitle,
                    price: parseFloat(item.product.productPrice),
                    quantity: item.quantity,
                    size: item.size,
                    color: item.color,
                    design_id: item.product.productId,
                    caption: item.caption
                }))
            };
            console.log('Sending request body:', JSON.stringify(requestBody, null, 2));

            const response = await fetch('/create-checkout-session/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': getCookie('csrftoken'),
                },
                body: JSON.stringify(requestBody),
            });

            console.log('Response received:', response);
            if (!response.ok) {
                console.error('Response not OK. Status:', response.status);
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            console.log('Parsed response data:', data);
            if (data.sessionUrl) {
                console.log('Redirecting to session URL:', data.sessionUrl);
                window.location.href = data.sessionUrl;
            } else {
                console.error('No session URL received in response data');
            }
        } catch (error) {
            console.error('Error during checkout process:', error);
        }
    };

    const isCartEmpty = cartItems.length === 0;

    return (
        <Button variant="primary" onClick={handleCheckout} disabled={isCartEmpty}>
            Checkout
        </Button>
    );
};

// CartModal Component
const CartModal = ({ cartItems, show, handleClose, updateCartItemQuantity, removeCartItem }) => {
    // Group items by productId, size, color, and caption
    const groupedItems = cartItems.reduce((acc, item) => {
        const key = `${item.product.productId}-${item.size}-${item.color}-${item.caption}`;
        if (!acc[key]) {
            acc[key] = { ...item };
        }
        return acc;
    }, {});

    const handleQuantityChange = (key, newQuantity) => {
        if (newQuantity >= 1) {
            updateCartItemQuantity(key, newQuantity);
        }
    };

    const handleRemoveItem = (key) => {
        removeCartItem(key);
    };

    const totalAmount = Object.values(groupedItems).reduce((total, item) =>
        total + (parseFloat(item.product.productPrice) * item.quantity), 0).toFixed(2);

    return (
        <Modal show={show} onHide={handleClose} centered aria-labelledby="contained-modal-title-vcenter" size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Shopping Cart</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {Object.keys(groupedItems).length > 0 ? (
                    <>
                        <div className="table-responsive">
                            <table className="table table-sm">
                                <thead>
                                    <tr>
                                        <th>Product</th>
                                        <th>Details</th>
                                        <th>Price</th>
                                        <th>Quantity</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.keys(groupedItems).map((key) => {
                                        const item = groupedItems[key];
                                        return (
                                            <tr key={key}>
                                                <td>
                                                    <Image 
                                                        src={item.product.productImage} 
                                                        alt={item.product.productAlt} 
                                                        fluid 
                                                        style={{ maxWidth: '50px' }} 
                                                        onContextMenu={(e) => {
                                                            e.preventDefault();
                                                            trackEvent('Cart', 'Right-click-cart-img', item.product.productTitle);
                                                        }}
                                                        draggable="false"
                                                    />
                                                </td>
                                                <td>
                                                    <div>{item.product.productTitle}</div>
                                                    <small className="text-muted">
                                                        Size: {item.size}, Color: {item.color}
                                                        {item.caption && <>, Caption: {item.caption}</>}
                                                    </small>
                                                </td>
                                                <td>${parseFloat(item.product.productPrice).toFixed(2)}</td>
                                                <td>
                                                    <Form.Control
                                                        type="number"
                                                        value={item.quantity}
                                                        onChange={(e) => handleQuantityChange(key, parseInt(e.target.value, 10))}
                                                        min={1}
                                                        size="sm"
                                                    />
                                                </td>
                                                <td>
                                                    <Button variant="danger" size="sm" onClick={() => handleRemoveItem(key)}>
                                                        Remove
                                                    </Button>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <div className="text-end mt-3">
                            <div><strong>Total: ${totalAmount}</strong></div>
                            <small className="text-muted">
                                Shipping and taxes calculated at checkout.
                            </small>
                        </div>
                    </>
                ) : (
                    <p>Your cart is empty.</p>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Checkout cartItems={Object.values(groupedItems)} />
            </Modal.Footer>
        </Modal>
    );
};

export default CartModal;
