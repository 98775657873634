import React, { useState, useRef, useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';

const ImageEditor = ({ initialImage, onImageEdited }) => {
  const [image, setImage] = useState(null);
  const [brushSize, setBrushSize] = useState(20);
  const [mask, setMask] = useState(null);
  const [editPrompt, setEditPrompt] = useState('');
  const canvasRef = useRef(null);
  const maskCanvasRef = useRef(null);
  const originalCanvasRef = useRef(null);
  const lastPositionRef = useRef(null);
  const cursorCanvasRef = useRef(null);
  const [undoStack, setUndoStack] = useState([]);
  const [scale, setScale] = useState(1);
  const containerRef = useRef(null);

  useEffect(() => {
    if (initialImage) {
      const img = new Image();
      img.onload = () => {
        setImage(img);
        calculateScale(img);
      };
      img.src = initialImage;
    }
  }, [initialImage]);

  const calculateScale = (img) => {
    const updateScale = () => {
      const container = containerRef.current;
      if (!container) return;

      const maxWidth = container.clientWidth;
      const maxHeight = container.clientHeight;
      const scaleX = maxWidth / img.width;
      const scaleY = maxHeight / img.height;
      const newScale = Math.min(scaleX, scaleY, 1); // Don't scale up, only down
      setScale(newScale);
    };

    updateScale();
    window.addEventListener('resize', updateScale);
    return () => window.removeEventListener('resize', updateScale);
  };

  useEffect(() => {
    if (image) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      canvas.width = image.width * scale;
      canvas.height = image.height * scale;
      ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

      // Initialize original canvas
      const originalCanvas = document.createElement('canvas');
      originalCanvas.width = image.width;
      originalCanvas.height = image.height;
      const originalCtx = originalCanvas.getContext('2d');
      originalCtx.drawImage(image, 0, 0);
      originalCanvasRef.current = originalCanvas;

      // Initialize mask
      const maskCanvas = document.createElement('canvas');
      maskCanvas.width = canvas.width;
      maskCanvas.height = canvas.height;
      setMask(maskCanvas);
      maskCanvasRef.current = maskCanvas;

      // Initialize cursor canvas
      const cursorCanvas = cursorCanvasRef.current;
      cursorCanvas.width = canvas.width;
      cursorCanvas.height = canvas.height;
      cursorCanvas.style.position = 'absolute';
      cursorCanvas.style.pointerEvents = 'none';
      cursorCanvas.style.top = '0';
      cursorCanvas.style.left = '0';

      // Clear undo stack
      setUndoStack([]);
    }
  }, [image, scale]);

  const drawLine = (ctx, x1, y1, x2, y2) => {
    ctx.beginPath();
    ctx.moveTo(x1, y1);
    ctx.lineTo(x2, y2);
    ctx.lineWidth = brushSize / scale;
    ctx.lineCap = 'round';
    ctx.strokeStyle = 'yellow';
    ctx.stroke();
  };

  const getCoordinates = (e) => {
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const clientX = e.clientX || (e.touches && e.touches[0].clientX);
    const clientY = e.clientY || (e.touches && e.touches[0].clientY);
    const x = (clientX - rect.left) / scale;
    const y = (clientY - rect.top) / scale;
    return { x, y };
  };

  const handleStart = (e) => {
    e.preventDefault();
    const { x, y } = getCoordinates(e);
    lastPositionRef.current = { x, y };

    // Save current state for undo
    const maskCtx = maskCanvasRef.current.getContext('2d');
    setUndoStack([...undoStack, maskCtx.getImageData(0, 0, canvasRef.current.width, canvasRef.current.height)]);
  };

  const handleMove = (e) => {
    e.preventDefault();
    const { x, y } = getCoordinates(e);

    // Update cursor
    const cursorCtx = cursorCanvasRef.current.getContext('2d');
    cursorCtx.clearRect(0, 0, cursorCanvasRef.current.width, cursorCanvasRef.current.height);
    cursorCtx.beginPath();
    cursorCtx.arc(x * scale, y * scale, brushSize / 2, 0, 2 * Math.PI);
    cursorCtx.strokeStyle = 'black';
    cursorCtx.stroke();

    if (!lastPositionRef.current) return; // Only draw when touch or mouse is pressed

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const maskCtx = maskCanvasRef.current.getContext('2d');

    // Draw line on mask
    drawLine(maskCtx, lastPositionRef.current.x, lastPositionRef.current.y, x, y);

    lastPositionRef.current = { x, y };

    // Clear the main canvas and redraw the original image
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

    // Draw the mask on the main canvas with constant opacity
    ctx.globalAlpha = 0.8;
    ctx.drawImage(maskCanvasRef.current, 0, 0);
    ctx.globalAlpha = 1;
  };

  const handleEnd = () => {
    lastPositionRef.current = null;
  };

  const applyMaskToImage = (originalImage, maskImage) => {
    const canvas = document.createElement('canvas');
    canvas.width = originalImage.width;
    canvas.height = originalImage.height;
    const ctx = canvas.getContext('2d');

    // Draw the original image
    ctx.drawImage(originalImage, 0, 0);

    // Get the image data
    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const data = imageData.data;

    // Get the mask data
    ctx.drawImage(maskImage, 0, 0);
    const maskData = ctx.getImageData(0, 0, canvas.width, canvas.height).data;

    // Apply the mask
    for (let i = 0; i < data.length; i += 4) {
      if (maskData[i] === 255 && maskData[i + 1] === 255 && maskData[i + 2] === 0) {
        // If the pixel is yellow in the mask, make it transparent in the original
        data[i + 3] = 0;
      }
    }

    // Put the modified image data back on the canvas
    ctx.putImageData(imageData, 0, 0);

    return canvas.toDataURL('image/png');
  };

  const sendToBackend = async () => {
    if (!image || !maskCanvasRef.current || !editPrompt) {
      alert('Please draw a mask and enter an edit prompt.');
      return;
    }

    const originalImageData = originalCanvasRef.current.toDataURL('image/png');
    const maskedImageData = applyMaskToImage(image, maskCanvasRef.current);

    try {
      const response = await fetch('/api/edit_image/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': getCookie('csrftoken'),
        },
        body: JSON.stringify({
          original_image: originalImageData,
          mask_image: maskedImageData,
          prompt: editPrompt,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      onImageEdited(data.edited_image);
    } catch (error) {
      console.error('Error sending data to backend:', error);
      alert('An error occurred while sending data to the backend.');
    }
  };

  // Add this function to get the CSRF token
  function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.substring(0, name.length + 1) === (name + '=')) {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  }

  const handleUndo = () => {
    if (undoStack.length > 0) {
      const previousState = undoStack[undoStack.length - 1];
      const maskCtx = maskCanvasRef.current.getContext('2d');
      maskCtx.putImageData(previousState, 0, 0);

      // Redraw the main canvas
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
      ctx.globalAlpha = 0.8;
      ctx.drawImage(maskCanvasRef.current, 0, 0);
      ctx.globalAlpha = 1;

      // Remove the last state from the undo stack
      setUndoStack(undoStack.slice(0, -1));
    }
  };

  return (
    <div>
      <Form.Group>
        <Form.Label>Brush Size: {brushSize}px</Form.Label>
        <Form.Range
          min="1"
          max="100"
          value={brushSize}
          onChange={(e) => setBrushSize(parseInt(e.target.value))}
        />
      </Form.Group>
      {image && (
        <div ref={containerRef} style={{ position: 'relative', width: '80vw', height: '70vh', overflow: 'auto' }}>
          <canvas
            ref={canvasRef}
            onMouseDown={handleStart}
            onMouseMove={handleMove}
            onMouseUp={handleEnd}
            onMouseOut={handleEnd}
            onTouchStart={handleStart}
            onTouchMove={handleMove}
            onTouchEnd={handleEnd}
            style={{ border: '1px solid black', cursor: 'none', touchAction: 'none' }}
          />
          <canvas
            ref={cursorCanvasRef}
            style={{ position: 'absolute', top: 0, left: 0, pointerEvents: 'none' }}
          />
        </div>
      )}
      <Form.Group className="mt-3">
        <Form.Label>Edit Prompt</Form.Label>
        <Form.Control
          as="textarea"
          rows={4}
          value={editPrompt}
          onChange={(e) => setEditPrompt(e.target.value)}
          placeholder="Enter edit prompt here..."
        />
      </Form.Group>
      <div className="mt-3">
        <Button variant="primary" onClick={sendToBackend} className="me-2">
          Send to Backend
        </Button>
        <Button variant="secondary" onClick={handleUndo} disabled={undoStack.length === 0}>
          Undo
        </Button>
      </div>
    </div>
  );
};

export default ImageEditor;