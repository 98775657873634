import React from 'react';
import { Carousel } from 'react-bootstrap';

const CustomerDesigns = () => {
    const designs = [
        { id: 1, image: '/static/customer-designs/pumpkin.jpg' },
        { id: 2, image: '/static/customer-designs/giraffe.jpg' },
        { id: 3, image: '/static/customer-designs/giraffe-black.jpg' },
    ];

    return (
        <div className="mx-auto">
            <Carousel interval={1500}  data-bs-theme="dark">
                {designs.map((design) => (
                    <Carousel.Item key={design.id}>
                        <img
                            className="d-block w-100"
                            src={design.image}
                            alt={design.caption}
                        />
                    </Carousel.Item>
                ))}
            </Carousel>
        </div>
    );
};

export default CustomerDesigns;
