import React, { useState } from 'react';
import { Row, Col, Button, Card, Modal } from 'react-bootstrap';
import './ImageMaker.css';  // Import the custom CSS file
import ProductOptions from "./ProductOptions";
import { trackEvent } from './trackGA';
import { TSHIRT_PRICE } from './Price';

// SingleCard Component
const SingleCard = ({ product, addToCart, setAlertMessage, index }) => {
    const [selectedSize, setSelectedSize] = useState('');
    const [selectedColor, setSelectedColor] = useState('');
    const [showModal, setShowModal] = useState(false);

    const handleAddToCart = () => {
        trackEvent('Product', 'Attempt Add to Cart', product.productId);
        if (selectedSize && selectedColor) {
            addToCart(product, selectedSize, selectedColor);
        } else {
            if (!selectedSize) {
                setAlertMessage('Please select a size.');
            } else if (!selectedColor) {
                setAlertMessage('Please select a color.');
            }
        }
    };

    const handleImageClick = () => {
        setShowModal(true);
    };

    const handleImageContextMenu = (e) => {
        e.preventDefault();
        trackEvent('Product', 'Right-click-card-image', product.productId);
    };

    // Create a product URL
    const productUrl = `${window.location.origin}/?product=${product.productId}`;

    return (
        <Col lg={3} className="mt-3">
            <div itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem" className="h-100">
                <meta itemProp="position" content={index + 1} />
                <Card className="shadow h-100 d-flex flex-column product-card" data-product-id={product.productId} data-product-price={product.productPrice}>
                    <div itemScope itemType="http://schema.org/Product" itemProp="item" className="d-flex flex-column h-100">
                        <meta itemProp="productID" content={product.productId} />
                        <meta itemProp="brand" content="AI Paint Tee" />
                        <link itemProp="url" href={productUrl} />
                        <Card.Img
                            variant="top"
                            src={product.productImage}
                            alt={product.productAlt}
                            onClick={handleImageClick}
                            style={{ cursor: 'pointer' }}
                            itemProp="image"
                            onContextMenu={handleImageContextMenu}
                            draggable="false"
                            onDragStart={(e) => e.preventDefault()}
                        />
                        <Card.Body className="text-start d-flex flex-column">
                            <Card.Title><span itemProp="name">{product.productTitle}</span></Card.Title>
                            <Card.Text><span itemProp="description">{product.productDescription}</span><br />
                                <a href="#ai-designer" className="text-info">Make your own by AI?</a>
                            </Card.Text>

                            <div className="mt-auto">
                                <ProductOptions
                                    selectedColor={selectedColor}
                                    setSelectedColor={setSelectedColor}
                                    selectedSize={selectedSize}
                                    setSelectedSize={setSelectedSize}
                                />

                                <div itemProp="offers" itemScope itemType="http://schema.org/Offer" className="mt-auto">
                                    <meta itemProp="priceCurrency" content="USD" />
                                    <meta itemProp="price" content={product.productPrice} />
                                    <link itemProp="availability" href="http://schema.org/InStock" />
                                    <link itemProp="url" href={productUrl} />
                                    <Button
                                        variant="primary"
                                        className="add-to-cart-btn mt-2 text-start w-100"
                                        onClick={handleAddToCart}
                                    >
                                        Add to Cart (${product.productPrice})
                                    </Button>
                                </div>
                            </div>
                        </Card.Body>
                    </div>
                </Card>
            </div>

            <Modal show={showModal} onHide={() => setShowModal(false)} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>{product.productTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <img
                        src={product.productImage}
                        alt={product.productAlt}
                        style={{ width: '100%', height: 'auto' }}
                    />
                </Modal.Body>
            </Modal>
        </Col>
    );
};


const ProductCardsTemplate = ({ addToCart, setAlertMessage, products }) => {
    return (
        products.length > 0 &&
        <>
            <h2 className="mt-5 mb-3 display-5 fw-bold" id="predesigned-t-shirts">Pre-Designed by AI</h2>
            <div itemScope itemType="http://schema.org/ItemList">
                <Row className="mt-3 mb-5">
                    {products.map((product, index) => (
                        <SingleCard
                            key={product.productId}
                            product={product}
                            addToCart={addToCart}
                            setAlertMessage={setAlertMessage}
                            index={index}
                        />
                    ))}
                </Row>
            </div>
        </>
    );
};


const ProductCardsHusbandWife = ({ addToCart, setAlertMessage }) => {
    // Product data
    const products = [
        // {
        //     productId: 'husband-wife/clever-football-watching-husband',
        //     productImage: '/static/husband-wife/clever_football-watching_husband.png',
        //     productAlt: 'Clever football-watching husband',
        //     productTitle: 'Clever football-watching husband',
        //     productDescription: 'Perfect for the husband who loves football a bit too much!',
        //     productPrice: TSHIRT_PRICE,
        // },
        // {
        //     productId: 'husband-wife/i-snore-she-ignores',
        //     productImage: '/static/husband-wife/i-snore-she-ignores.png',
        //     productAlt: 'I snore she ignores',
        //     productTitle: 'I snore she ignores',
        //     productDescription: 'For the husband who snores, and the wife who ignores!',
        //     productPrice: TSHIRT_PRICE,
        // },
        {
            productId: 'husband-wife/funny-constantly-farting-husband',
            productImage: '/static/husband-wife/funny_constantly-farting_husband.png',
            productAlt: 'Funny constantly-farting husband',
            productTitle: 'Funny constantly-farting husband',
            productDescription: 'For the husband who always blames the dog!',
            productPrice: TSHIRT_PRICE,
        },
        {
            productId: 'husband-wife/sarcastic-wine-loving-wife',
            productImage: '/static/husband-wife/sarcastic_wine-loving_wife.png',
            productAlt: 'Sarcastic wine-loving wife',
            productTitle: 'Sarcastic wine-loving wife',
            productDescription: 'For the wife who believes wine is always the answer!',
            productPrice: TSHIRT_PRICE,
        },
        // {
        //     productId: 'husband-wife/clever-messy-husband',
        //     productImage: '/static/husband-wife/clever_messy_husband.png',
        //     productAlt: 'Clever messy husband',
        //     productTitle: 'Clever messy husband',
        //     productDescription: 'For the husband who thinks chaos is an art form!',
        //     productPrice: TSHIRT_PRICE,
        // },
        {
            productId: 'husband-wife/funny-couch-potato-husband',
            productImage: '/static/husband-wife/funny_couch_potato_husband.png',
            productAlt: 'Funny couch potato husband',
            productTitle: 'Funny couch potato husband',
            productDescription: "For the husband who's one with the couch!",
            productPrice: TSHIRT_PRICE,
        },
        {
            productId: 'husband-wife/sarcastic-bossy-wife',
            productImage: '/static/husband-wife/sarcastic_bossy_wife.png',
            productAlt: 'Sarcastic bossy wife',
            productTitle: 'Sarcastic bossy wife',
            productDescription: 'For the wife who knows best... always!',
            productPrice: TSHIRT_PRICE,
        },
        {
            productId: 'husband-wife/snarky-dramatic-wife',
            productImage: '/static/husband-wife/snarky_dramatic_wife.png',
            productAlt: 'Snarky dramatic wife',
            productTitle: 'Snarky dramatic wife',
            productDescription: 'For the wife who turns everyday into a soap opera!',
            productPrice: TSHIRT_PRICE,
        },
        // {
        //     productId: 'husband-wife/clever-over-explaining-husband',
        //     productImage: '/static/husband-wife/clever_over-explaining_husband.png',
        //     productAlt: 'Clever over-explaining husband',
        //     productTitle: 'Clever over-explaining husband',
        //     productDescription: 'For the husband who never uses one word when ten will do!',
        //     productPrice: TSHIRT_PRICE,
        // },
        // {
        //     productId: 'husband-wife/funny-loud-snoring-husband',
        //     productImage: '/static/husband-wife/funny_loud-snoring_husband.png',
        //     productAlt: 'Funny loud-snoring husband',
        //     productTitle: 'Funny loud-snoring husband',
        //     productDescription: 'For the husband who keeps the whole neighborhood awake!',
        //     productPrice: TSHIRT_PRICE,
        // },
        {
            productId: 'husband-wife/sarcastic-over-packing-wife',
            productImage: '/static/husband-wife/sarcastic_over-packing_wife.png',
            productAlt: 'Sarcastic over-packing wife',
            productTitle: 'Sarcastic over-packing wife',
            productDescription: 'For the wife who packs for every possible scenario... and then some!',
            productPrice: TSHIRT_PRICE,
        },
        // {
        //     productId: 'husband-wife/clever-snoring-husband',
        //     productImage: '/static/husband-wife/clever_snoring_husband.png',
        //     productAlt: 'Clever snoring husband',
        //     productTitle: 'Clever snoring husband',
        //     productDescription: "For the husband who insists he doesn't snore... loudly!",
        //     productPrice: TSHIRT_PRICE,
        // },
        // {
        //     productId: 'husband-wife/funny-vacation-planning-wife',
        //     productImage: '/static/husband-wife/funny_vacation-planning_wife.png',
        //     productAlt: 'Funny vacation-planning wife',
        //     productTitle: 'Funny vacation-planning wife',
        //     productDescription: 'For the wife who starts planning the next trip before the current one ends!',
        //     productPrice: TSHIRT_PRICE,
        // },
        // {
        //     productId: 'husband-wife/sarcastic-party-planning-wife',
        //     productImage: '/static/husband-wife/sarcastic_party-planning_wife.png',
        //     productAlt: 'Sarcastic party-planning wife',
        //     productTitle: 'Sarcastic party-planning wife',
        //     productDescription: 'For the wife who turns every gathering into an event!',
        //     productPrice: TSHIRT_PRICE,
        // },
        // {
        //     productId: 'husband-wife/witty-forgetful-husband',
        //     productImage: '/static/husband-wife/witty_forgetful_husband.png',
        //     productAlt: 'Witty forgetful husband',
        //     productTitle: 'Witty forgetful husband',
        //     productDescription: "For the husband who can't remember where he put his keys... or anything else!",
        //     productPrice: TSHIRT_PRICE,
        // },
        // {
        //     productId: 'husband-wife/clever-video-game-addicted-husband',
        //     productImage: '/static/husband-wife/clever_video-game-addicted_husband.png',
        //     productAlt: 'Clever video-game-addicted husband',
        //     productTitle: 'Clever video-game-addicted husband',
        //     productDescription: 'For the husband who lives in a virtual world!',
        //     productPrice: TSHIRT_PRICE,
        // },
        // {
        //     productId: 'husband-wife/humorous-clueless-husband',
        //     productImage: '/static/husband-wife/humorous_clueless_husband.png',
        //     productAlt: 'Humorous clueless husband',
        //     productTitle: 'Humorous clueless husband',
        //     productDescription: "For the husband who's perpetually confused... about everything!",
        //     productPrice: TSHIRT_PRICE,
        // },
        // {
        //     productId: 'husband-wife/sarcastic-picky-eating-wife',
        //     productImage: '/static/husband-wife/sarcastic_picky-eating_wife.png',
        //     productAlt: 'Sarcastic picky-eating wife',
        //     productTitle: 'Sarcastic picky-eating wife',
        //     productDescription: 'For the wife with a very... particular palate!',
        //     productPrice: TSHIRT_PRICE,
        // },
        // {
        //     productId: 'husband-wife/witty-tool-shedding-husband',
        //     productImage: '/static/husband-wife/witty_tool-shedding_husband.png',
        //     productAlt: 'Witty tool-shedding husband',
        //     productTitle: 'Witty tool-shedding husband',
        //     productDescription: 'For the husband who\'s always "organizing" his tools!',
        //     productPrice: TSHIRT_PRICE,
        // },
        {
            productId: 'husband-wife/funny-beer-loving-husband',
            productImage: '/static/husband-wife/funny_beer-loving_husband.png',
            productAlt: 'Funny beer-loving husband',
            productTitle: 'Funny beer-loving husband',
            productDescription: 'For the husband who thinks every hour is happy hour!',
            productPrice: TSHIRT_PRICE,
        },
        // {
        //     productId: 'husband-wife/humorous-diy-project-starting-husband',
        //     productImage: '/static/husband-wife/humorous_diy-project-starting_husband.png',
        //     productAlt: 'Humorous DIY-project-starting husband',
        //     productTitle: 'Humorous DIY-project-starting husband',
        //     productDescription: 'For the husband who starts projects... but never finishes them!',
        //     productPrice: TSHIRT_PRICE,
        // },
        // {
        //     productId: 'husband-wife/sarcastic-spa-obsessed-wife',
        //     productImage: '/static/husband-wife/sarcastic_spa-obsessed_wife.png',
        //     productAlt: 'Sarcastic spa-obsessed wife',
        //     productTitle: 'Sarcastic spa-obsessed wife',
        //     productDescription: 'For the wife who believes in the healing power of facials and massages!',
        //     productPrice: TSHIRT_PRICE,
        // },
        {
            productId: 'husband-wife/sarcastic-mean-wife',
            productImage: '/static/husband-wife/sarcastic-mean-wife.png',
            productAlt: 'Sarcastic mean wife',
            productTitle: 'Sarcastic mean wife',
            productDescription: 'For the wife who always has a sharp word for everything!',
            productPrice: TSHIRT_PRICE,
        },
        // {
        //     productId: 'husband-wife/funny-lazy-husband',
        //     productImage: '/static/husband-wife/funny-lazy-husband.png',
        //     productAlt: 'Funny lazy husband',
        //     productTitle: 'Funny lazy husband',
        //     productDescription: 'For the husband who\'s always "organizing" his tools!',
        //     productPrice: TSHIRT_PRICE,
        // },
        {
            productId: 'husband-wife/humorous-lazy-husband',
            productImage: '/static/husband-wife/humorous-lazy-husband.png',
            productAlt: 'Humorous lazy husband',
            productTitle: 'Humorous lazy husband',
            productDescription: 'For lazy husbands, from wifes?',
            productPrice: TSHIRT_PRICE,
        },
        {
            productId: 'husband-wife/super-funny-lazy-husband',
            productImage: '/static/husband-wife/super-funny-lazy-husband.png',
            productAlt: 'Super funny lazy husband',
            productTitle: 'Super funny lazy husband',
            productDescription: 'For lazy husbands, from wives?',
            productPrice: TSHIRT_PRICE,
        },
    ];

    return (
        <ProductCardsTemplate
            addToCart={addToCart}
            setAlertMessage={setAlertMessage}
            products={products}
        />
    );
};

const ProductCardsGeneral = ({ addToCart, setAlertMessage }) => {
    // Product data
    const products = [
        {
            productId: 'birthday/made-in-2019',
            productImage: '/static/birthday/made-in-2019.png',
            productAlt: 'Made in 2019',
            productTitle: 'For kids "Made in 2019"',
            productDescription: 'Make a Cartoon t-shirt for birthday. Details: Must include text "Made in 2019"',
            productPrice: TSHIRT_PRICE,
        },
        {
            productId: 'sarcastic-mean-mom-from-a-son',
            productImage: '/static/sarcastic-mean-mom-from-a-son.png',
            productAlt: 'Sarcastic mean mom from a son',
            productTitle: 'Sarcastic mean mom from a son',
            productDescription: 'A great gift for mean mom from a son?',
            productPrice: TSHIRT_PRICE,
        },
        {
            productId: 'minimalism-coffee-enthusiasts',
            productImage: '/static/minimalism_coffee_enthusiasts.png',
            productAlt: 'Minimalism coffee enthusiasts',
            productTitle: 'Minimalism coffee enthusiasts',
            productDescription: 'For those who believe less is more, except when it comes to coffee!',
            productPrice: TSHIRT_PRICE,
        },
        {
            productId: 'humorous-madisoin-wi-residents',
            productImage: '/static/humorous-madisoin-wi-residents.png',
            productAlt: 'Humorous Madison wi residents',
            productTitle: 'Humorous for Madison Wi Residents',
            productDescription: 'For Madison residents who love cheese, bikes, and badger?',
            productPrice: TSHIRT_PRICE,
        },
        {
            productId: 'funny-new-driver',
            productImage: '/static/funny-new-driver.png',
            productAlt: 'Funny new driver',
            productTitle: 'Funny new driver',
            productDescription: 'A great gift for new drivers?',
            productPrice: TSHIRT_PRICE,
        },
        {
            productId: 'sarcastic-annoying-kids',
            productImage: '/static/sarcastic-annoying-kids.png',
            productAlt: 'Sacarstic annoying kids',
            productTitle: 'Sacarstic annoying kids',
            productDescription: 'A great gift for annoying kids?',
            productPrice: TSHIRT_PRICE,
        },
        {
            productId: 'chess-boy',
            productImage: '/static/chess-boy.png',
            productAlt: 'Boy playing chess',
            productTitle: 'Boys playing chess',
            productDescription: 'Kids would love this.',
            productPrice: TSHIRT_PRICE,
        },
        {
            productId: 'swim',
            productImage: '/static/swim.png',
            productAlt: 'Boy swimming',
            productTitle: 'A boy swimming really fast',
            productDescription: 'A great gift for kids who love swimming.',
            productPrice: TSHIRT_PRICE,
        },
        {
            productId: 'tennis-boy',
            productImage: '/static/tennis-boy.png',
            productAlt: 'Boy playing tennis',
            productTitle: 'A boy playing tennis',
            productDescription: 'A great gift for friends who love tennis.',
            productPrice: TSHIRT_PRICE,
        },
        {
            productId: 'sarcastic-soft-engineers-in-tech-companies',
            productImage: '/static/sarcastic-soft-engineers-in-tech-companies.png',
            productAlt: 'Sarcastic software engineer in tech companies',
            productTitle: 'Sarcastic software engineer in tech companies',
            productDescription: 'A great gift for software engineer in tech companies?',
            productPrice: TSHIRT_PRICE,
        },
        {
            productId: 'vintage-cat-lovers',
            productImage: '/static/vintage_cat_lovers.png',
            productAlt: 'Vintage cat lovers',
            productTitle: 'Vintage cat lovers',
            productDescription: 'For those who believe cats are timeless!',
            productPrice: TSHIRT_PRICE,
        },
    ];

    return (
        <ProductCardsTemplate
            addToCart={addToCart}
            setAlertMessage={setAlertMessage}
            products={products}
        />
    );
};

const ProductCards = ({ addToCart, setAlertMessage }) => {
    return (
        <>
            <ProductCardsGeneral
                addToCart={addToCart}
                setAlertMessage={setAlertMessage}
            />
        </>
    );
};

export default ProductCardsTemplate;
