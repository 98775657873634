import React, { useState, useEffect } from 'react';
import { Container, Alert } from 'react-bootstrap';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import PageAlert from './PageAlert';
import ImageMaker from './ImageMaker';
import SiteInfo from './SiteInfo';
import ProductCardsTemplate from './ProductCards';
import CartModal from './CartModal';
import SuccessPage from './SuccessPage';  // Import the new SuccessPage component
import CancelPage from './CancelPage';  // Import the new CancelPage component
import ReactGA from 'react-ga4';
import { trackEvent, trackPageview } from './trackGA';
import CustomerDesigns from './CustomerDesigns';
import CustomerPage from './CustomerPage';
import ImageEditor from './ImageEditor';
import EmailPopup from './EmailPopup';
import { useDocumentTitle } from './useDocumentTitle';

const TRACKING_ID = "G-97H3QJ4DCQ";

function CartIcon({ itemCount, handleCartClick }) {
  console.log("item_count", itemCount);
  return (
    <>
      <style type="text/css">
        {`
    .cart-icon {
      position: fixed;
      top: 20px;
      right: 20px;
      font-size: 24px;
      cursor: pointer;
      z-index: 1000; /* Ensure the cart appears on top of all other components */
    }

    .cart-badge {
      position: absolute;
      top: 0;
      right: 0;
      background-color: red;
      color: white;
      border-radius: 50%;
      padding: 2px 6px;
      font-size: 12px;
    }
    `}
      </style>

      <div
        className="cart-icon d-flex"
        id="cart-icon"
        style={{ cursor: "pointer" }}
        onClick={handleCartClick}
      >
        {/* SVG for a realistic shopping cart icon */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={30}
          height={30}
          fill="currentColor"
          className="bi bi-cart me-2"
          viewBox="0 0 16 16"
        >
          <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5M3.102 4l1.313 7h8.17l1.313-7zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4m7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4m-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2m7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2" />
        </svg>
        <span
          className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"
          id="cart-count"
        >
          {itemCount || 0}
        </span>
      </div>
    </>
  );
}

const HeaderText = ({ line }) => {
  return (
    <>
      {line} by{' '}
      <span
        style={{
          background: 'linear-gradient(to right, red, orange, green, blue, indigo, violet)',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
          backgroundClip: 'text',
          color: 'transparent'
        }}
      >
        AI
      </span>
    </>
  );
}

const MainGeneric = () => {
  useDocumentTitle('AI Paint Tee - Personalize T-Shirts for Anyone/Any Occasion');
  return (
    <Main
      headerText={<HeaderText line="Design and Print T-Shirts For Anyone/Any Occasion" />}
      subheaderText="Customize your t-shirt with AI.  Ready to order in seconds."
      styles={['Cute', 'Sarcastic', 'Minimalist', 'Logo', 'Cartoon', 'Humorous', 'Funny', 'Vintage', 'Humorous and Cartoon']}
      targets={["Baby boy", "Baby girl", "Lazy Husband", "Mean Wife", "Boyfriend who doesn't listen", "Girlfriend who loves to shop", "Dad who loves to grill", "Mom who loves to bake", "Brother", "Christmas Party"]}
      products={[]}
    />
  );
}

const MainInfant = () => {
  useDocumentTitle('AI Paint Tee - Personalize Cute T-Shirts for Infants');

  return (
    <Main
      headerText={<HeaderText line="Personalize and Print Cute T-Shirts For Infants and Toddlers" />}
      subheaderText="Customize your baby's t-shirt with AI. Ready to order in seconds."
      styles={['Cute', "Minecraft", 'Minimalist', 'Logo', 'Cartoon', 'Humorous', 'Funny', 'Vintage', 'Humorous and Cartoon']}
      targets={["Infants", "Toddlers", "Baby boy", "Baby girl", "Baby twins"]}
      products={[]}
    />
  );
}

const MainToddler = () => {
  useDocumentTitle('AI Paint Tee - Personalize Cute T-Shirts for Toddlers');

  return (
    <Main
      headerText={<HeaderText line="Personalize and Print Cute T-Shirts For Toddlers" />}
      subheaderText="Customize your toddler's t-shirt with AI. Ready to order in seconds."
      styles={['Cute', "Minecraft", 'Minimalist', 'Logo', 'Cartoon', 'Humorous', 'Funny', 'Vintage', 'Humorous and Cartoon']}
      targets={["Toddlers", "Toddler boy", "Toddler girl", "Toddler twins"]}
      products={[]}
    />
  );
}


const MainKid = () => {
  useDocumentTitle('AI Paint Tee - Personalize T-Shirts for Kids');

  return (
    <Main
      headerText={<HeaderText line="Personalize and Print T-Shirts For Kids" />}
      subheaderText="Customize your kid t-shirt with AI. Ready to order in seconds."
      styles={['Cute', "Minecraft style", 'Minimalist', 'Logo', 'Cartoon', 'Humorous', 'Funny', 'Vintage', 'Humorous and Cartoon']}
      targets={["Kid", "Boy", "Girl", "Boy who loves to play video games", "Girl who loves to play with dolls"]}
      products={[]}
    />
  );
}

const MainDesignByKids = () => {
  useDocumentTitle('AI Paint Tee - Kids Design Their Own T-Shirts by AI');

  return (
    <Main
      headerText={<HeaderText line="Kids Design Their Own T-Shirts" />}
      subheaderText="Enter kids' ideas for t-shirts and let AI paint them. What? A cat swimming in the ocean? A giraffe solving a rubik's cube?"
      styles={['Cute', "Minecraft style", 'Minimalist', 'Logo', 'Cartoon', 'Humorous', 'Funny', 'Vintage', 'Humorous and Cartoon']}
      targets={["Kid", "Boy", "Girl", "Boy who loves to play video games", "Girl who loves to play with dolls"]}
      products={[]}
    />
  );
}


const MainYouth = () => {
  useDocumentTitle('AI Paint Tee - Personalize T-Shirts for Youth');

  return (
    <Main
      headerText={<HeaderText line="Personalize and Print T-Shirts For Youth" />}
      subheaderText="Customize your youth t-shirt with AI. Ready to order in seconds."
      styles={['Cute', "Minecraft style", 'Minimalist', 'Logo', 'Cartoon', 'Humorous', 'Funny', 'Vintage', 'Humorous and Cartoon']}
      targets={["Teen", "Youth Boy", "Youth Girl", "High School Boy", "High School Girl", "Youth who loves to play video games", "Youth who loves to shop"]}
      products={[]}
    />
  );
}

const MainTeen = () => {
  useDocumentTitle('AI Paint Tee - Personalize T-Shirts for Teens');

  return (
    <Main
      headerText={<HeaderText line="Personalize and Print T-Shirts For Teens" />}
      subheaderText="Customize your teen t-shirt with AI. Ready to order in seconds."
      styles={['Cute', "Minecraft style", 'Minimalist', 'Logo', 'Cartoon', 'Humorous', 'Funny', 'Vintage', 'Humorous and Cartoon']}
      targets={["Teen", "High School Boy", "High School Girl", "Gamer Boy", "Gamer Girl", "Teen who loves to play video games", "Teen who loves to shop"]}
      products={[]}
    />
  );
}

const MainMinecraft = () => {
  useDocumentTitle('AI Paint Tee - Personalize and Print T-Shirts for Minecraft Fans');

  return (
    <Main
      headerText={<HeaderText line="Personalize and Print T-Shirts For Minecraft Fans" />}
      subheaderText="Customize your unique t-shirt with AI. Ready to order in seconds."
      styles={['Cartoon', 'Cute', 'Minimalist', 'Logo', 'Humorous', 'Funny', 'Vintage', 'Humorous and Cartoon']}
      targets={["Minecraft fans", "Pokemon fans", "Gaming fans", "Video game fans"]}
      products={[]}
      showCustomerTestimonials={false}
    />
  );
}

const MainStrangerThings = () => {
  useDocumentTitle('AI Paint Tee - Personalize and Print T-Shirts for Stranger Things Fans');

  return (
    <Main
      headerText={<HeaderText line="Personalize and Print T-Shirts For Stranger Things Fans" />}
      subheaderText="Customize your unique t-shirt with AI. Ready to order in seconds."
      styles={['Cartoon', 'Cute', 'Minimalist', 'Logo', 'Humorous', 'Funny', 'Vintage', 'Humorous and Cartoon']}
      targets={["Stranger Things fans", "Netflix fans", "Gaming fans", "Video game fans"]}
      products={[]}
      showCustomerTestimonials={false}
    />
  );
}

const Main = ({ headerText, subheaderText, styles, targets, products, showCustomerTestimonials = true }) => {
  const [cartItems, setCartItems] = useState([]);  // Manage shopping cart items
  const [showCartModal, setShowCartModal] = useState(false);  // Manage modal visibility
  const [alertMessage, setAlertMessage] = useState('');  // Manage alert message
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const gaDisabled = searchParams.get('ga_disabled') === 'true';

    if (gaDisabled) {
      console.log("Google Analytics is disabled via URL parameter.");
    } else {
      console.log("Initializing Google Analytics");
      ReactGA.initialize(TRACKING_ID);
      trackPageview("/", "Landing Page");
    }

    const src = searchParams.get('src');
    trackEvent('Acquisition', src, 'Landing Page');
  }, [location]);

  // Handle adding items to the cart (grouped by productId, size, and color)
  const addToCart = (product, size, color, caption) => {
    const existingItemIndex = cartItems.findIndex(
      (item) => item.product.productId === product.productId &&
        item.size === size &&
        item.color === color &&
        item.caption === caption
    );

    trackEvent('Product', 'Add to Cart', product.productId);

    if (existingItemIndex >= 0) {
      const updatedCartItems = [...cartItems];
      updatedCartItems[existingItemIndex].quantity += 1;
      setCartItems(updatedCartItems);
    } else {
      setCartItems([...cartItems, { product, size, color, caption, quantity: 1 }]);
    }
  };

  // Handle showing the cart modal
  const handleCartClick = () => {
    setShowCartModal(true);
  };

  // Handle closing the cart modal
  const handleClose = () => setShowCartModal(false);

  // Handle updating the quantity of an item in the cart
  const updateCartItemQuantity = (key, newQuantity) => {
    const updatedCartItems = cartItems.map((item) => {
      const itemKey = `${item.product.productId}-${item.size}-${item.color}-${item.caption}`;
      if (itemKey === key) {
        return { ...item, quantity: newQuantity };
      }
      return item;
    });
    setCartItems(updatedCartItems);
  };

  // Handle removing an item from the cart
  const removeCartItem = (key) => {
    const updatedCartItems = cartItems.filter((item) => {
      const itemKey = `${item.product.productId}-${item.size}-${item.color}-${item.caption}`;
      return itemKey !== key;
    });
    setCartItems(updatedCartItems);
  };

  // Count the total number of items in the cart
  const totalCartItems = cartItems.reduce((acc, item) => acc + item.quantity, 0);

  return (
    <>
      <CartIcon itemCount={totalCartItems} handleCartClick={handleCartClick} />
      <PageAlert msg={alertMessage} setAlertMessage={setAlertMessage} />

      <Container className="px-4 pt-5 mb-5 text-center">

        <div className="d-none d-xl-block">
          <img
            src="/static/brush-logo.svg"
            alt="Brush Logo"
            className="position-fixed top-0 start-0 m-3"
            style={{ width: '80px', height: 'auto', zIndex: 1000 }}
          />
        </div>

        <Container className="col-md-8">
          <h1 className="display-4 fw-bold text-body-emphasis">
            {headerText}
          </h1>
          <p className="lead fw-bold">
            {subheaderText}
          </p>

        </Container>

        {showCustomerTestimonials && (
          <Container className="col-md-6">
            <div className="border rounded py-2 px-3" style={{ backgroundColor: '#f8f9fa' }}>
              <h4 className="mt-3 mb-3 fw-bold" id="customer-designs">Designed by Customers with <span style={{ color: 'red' }}>❤️</span></h4>
              <div className="d-flex justify-content-center mb-2">
                {[...Array(5)].map((_, index) => (
                  <span key={index} style={{ color: '#FFD700', fontSize: '24px' }}>★</span>
                ))}
              </div>
              <p className="lead">"It is perfect" - E. B. (verified purchase, "a giraffe solving a rubik's cube")</p>
              <CustomerDesigns />
            </div>
          </Container>
        )}


        <h2 className="mt-5 mb-3 display-6 fw-bold">Design Your Own Now!</h2>
        <ImageMaker addToCart={addToCart} setAlertMessage={setAlertMessage} styles={styles} targets={targets} />

        <Container className="col-md-11">
          <ProductCardsTemplate addToCart={addToCart} setAlertMessage={setAlertMessage} products={products} />
          <SiteInfo />
        </Container>
      </Container>

      {/* CartModal component */}
      <CartModal
        cartItems={cartItems}
        show={showCartModal}
        handleClose={handleClose}
        updateCartItemQuantity={updateCartItemQuantity}
        removeCartItem={removeCartItem}
      />
    </>
  );
};

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainGeneric />} />
        <Route path="/infant" element={<MainInfant />} />
        <Route path="/toddler" element={<MainToddler />} />
        {/* <Route path="/kid" element={<MainKid />} /> */}
        <Route path="/kid" element={<MainDesignByKids />} />
        <Route path="/youth" element={<MainYouth />} />
        <Route path="/teen" element={<MainTeen />} />
        <Route path="/minecraft" element={<MainMinecraft />} />
        <Route path="/stranger-things" element={<MainStrangerThings />} />
        <Route path="/success" element={<SuccessPage />} />
        <Route path="/cancel" element={<CancelPage />} />
        <Route path="/customer" element={<CustomerPage />} />
        <Route path="/image-editor" element={<ImageEditor />} />
      </Routes>
    </Router>
  );
};

export default App;
